import React, { PureComponent, useRef, useState, useCallback } from 'react';
import { FieldArray } from 'formik';
import _ from 'lodash';
import styled from 'styled-components';
import { FieldWrapper } from './auxiliary/FieldWrapper';
import { colors } from '../../assets/styles/colors';
import { fieldWrapperProps } from '../../services/fieldUtils';
import { Modal } from '../modals/Modal';
import { ModalSection } from '../modals/ModalSection';
import { TextInput } from '../inputs/TextInput';
import { FormFooter } from '../form/FormFooter';
import { CancellationLink } from '../links/CancellationLink';
import { BrandButton } from '../buttons/BrandButton';
import { breakpointsForMaxWidth } from '../../assets/styles/grid';
import { preventDefault } from '../../services/utils';
import FileUploader from '../other/fileUploader';

export const FilesFieldContainer = styled.div`
  width: 100%;
`;

export const StyledAttachmentRow = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 3px;
  padding-bottom: ${props => (props.descriptionIsPresent ? '3px' : '10px')};
  width: 100%;
  @media only screen and (max-width: ${breakpointsForMaxWidth.small}) {
    flex-direction: column;
    align-items: flex-end;
  }
`;

export const StyledAttachmentNameSpan = styled.span`
  width: 100%;
  vertical-align: middle;
  display: inline-block;
  font-weight: bold;
`;

export const DescriptionLinkButton = styled.button`
  background: transparent;
  border: 0;
  padding-right: 0;
  padding-left: 0.5rem;
  color: ${colors.download};
  cursor: pointer;
  min-width: 120px;
  &:focus {
    outline: none;
  }
  &:hover {
    text-decoration: underline;
  }
  @media only screen and (max-width: ${breakpointsForMaxWidth.small}) {
    padding: 0;
    text-align: right;
  }
`;

export const StyledAttachmentLink = styled.a`
  color: ${colors.download};
  min-width: 80px;
  padding-left: 0.5rem;
  @media only screen and (max-width: ${breakpointsForMaxWidth.small}) {
    margin-left: 0;
    text-align: right;
  }
`;

export const StyledRemoveAttachmentButton = styled.button`
  background: transparent;
  border: 0;
  padding-right: 0;
  padding-left: 0.5rem;
  color: ${colors.delete};
  cursor: pointer;
  &:focus {
    outline: none;
  }
  &:hover {
    text-decoration: underline;
  }
  @media only screen and (max-width: ${breakpointsForMaxWidth.small}) {
    padding: 0;
    margin-left: 0;
    text-align: right;
  }
`;

export const StyledAttachmentDescriptionRow = styled.div`
  display: flex;
  align-items: center;
  padding: 0 3px;
  padding-bottom: 10px;
  width: 100%;
`;

export const StyledAttachmentsTable = styled.div`
  flex-direction: column;
  width: 100%;
`;

export const StyledMockUploadButton = styled.div`
  padding: 0.25rem 1rem;
  border: 1px solid ${colors.inputBorderColor};
  border-radius: 0.2rem;
  cursor: pointer;
  &:focus,
  &:hover {
    background: ${colors.inputBorderColor};
  }
`;

export const StyledMockUploadButtonTitle = styled.span`
  padding-left: 10px;
`;

const DescriptionAttachmentModalContent = props => {
  const { id, description, hide, addDescriptionToAttachment } = props;
  const [descriptionValue, setDescriptionValue] = useState(description);

  const onAdd = useCallback(() => {
    addDescriptionToAttachment(descriptionValue);
    hide();
  }, [descriptionValue, addDescriptionToAttachment, hide]);

  return (
    <ModalSection>
      <form onSubmit={preventDefault}>
        <TextInput
          id={`comment-${id}-text-input`}
          placeholder={I18n.t('file_uploader.description_placeholder')}
          value={descriptionValue}
          onChange={e => {
            setDescriptionValue(e.target.value);
          }}
        />
        <FormFooter>
          <CancellationLink onClick={hide} />
          <BrandButton onClick={onAdd}>{I18n.t('file_uploader.save')}</BrandButton>
        </FormFooter>
      </form>
    </ModalSection>
  );
};

const AttachmentRow = props => {
  const {
    id,
    pathString,
    description,
    filename,
    disabled,
    removeAttachment,
    addDescriptionToAttachment,
  } = props;
  const descriptionAttachmentModalRef = useRef(null);
  const descriptionIsPresent = description && description.length !== 0;
  const describeAction = descriptionIsPresent ? 'edit_description' : 'add_description';

  return (
    <>
      <StyledAttachmentRow descriptionIsPresent={descriptionIsPresent}>
        <StyledAttachmentNameSpan id={`${id}-name`}>{filename}</StyledAttachmentNameSpan>
        {!disabled && (
          <DescriptionLinkButton
            id={`${id}-description-button`}
            type="button"
            onClick={() => descriptionAttachmentModalRef.current.open()}
          >
            {I18n.t(`file_uploader.${describeAction}`)}
          </DescriptionLinkButton>
        )}
        <StyledAttachmentLink id={`${id}-link`} download={filename} href={pathString}>
          {I18n.t('file_uploader.download_file')}
        </StyledAttachmentLink>
        {!disabled && (
          <StyledRemoveAttachmentButton
            id={`${id}-remove-button`}
            type="button"
            onClick={removeAttachment}
          >
            {I18n.t('file_uploader.delete_file')}
          </StyledRemoveAttachmentButton>
        )}
      </StyledAttachmentRow>
      {descriptionIsPresent && (
        <StyledAttachmentDescriptionRow>{description}</StyledAttachmentDescriptionRow>
      )}
      <Modal title={I18n.t(`file_uploader.${describeAction}`)} ref={descriptionAttachmentModalRef}>
        {modalProps => (
          <DescriptionAttachmentModalContent
            {...modalProps}
            description={description}
            addDescriptionToAttachment={addDescriptionToAttachment}
          />
        )}
      </Modal>
    </>
  );
};

export class FilesField extends PureComponent {
  constructor(props) {
    super(props);
    this.maxFileSizeInMb = I18n.t('config.max_attachment_size_in_mb');
  }

  defaultOnChange = async e => {
    const { value, arrayHelpers, arrayHelpersMethod } = e;
    await arrayHelpers[arrayHelpersMethod](value);
    this.fileRef.value = null;
  };

  onUploadSuccess = (signedUrl, arrayHelpers) => {
    const { onChange } = this.props;
    const downloadPath = { url: signedUrl.signedUrl.split('?')[0] };
    const event = {
      value: downloadPath,
      arrayHelpers,
      arrayHelpersMethod: 'push',
    };
    // eslint-disable-next-line no-unused-expressions
    onChange ? onChange(event, this.defaultOnChange.bind(this)) : this.defaultOnChange(event);
  };

  onRemoveAttachment = (valueIndex, arrayHelpers, pathString, filename) => {
    const { onChange } = this.props;
    const event = {
      value: valueIndex,
      arrayHelpers,
      arrayHelpersMethod: 'remove',
      pathString,
      filename,
    };
    // eslint-disable-next-line no-unused-expressions
    onChange ? onChange(event, this.defaultOnChange.bind(this)) : this.defaultOnChange(event);
  };

  addDescriptionToAttachment = async (valueIndex, description, arrayHelpers, pathString) => {
    await arrayHelpers.replace(valueIndex, {
      url: pathString,
      description,
    });
    this.fileRef.value = null;
  };

  setInputRef = ref => {
    this.fileRef = ref;
  };

  prepareAttachmentsData() {
    const {
      field: { value: initialCollection },
    } = this.props;

    return initialCollection.map((attachment, valueIndex) => {
      const { url: pathString, description } = attachment;
      const path = new URL(pathString).pathname.split('/');
      const filename = decodeURIComponent(_.last(path));

      return { pathString, filename, description, valueIndex };
    });
  }

  renderAttachments(arrayHelpers, fieldId) {
    const { disabled, sortPredicate } = this.props;

    let attachments = this.prepareAttachmentsData();
    if (sortPredicate) attachments = attachments.sort(sortPredicate);

    return (
      <FilesFieldContainer>
        <StyledAttachmentsTable>
          {attachments.map(({ pathString, filename, description, valueIndex }) => (
            <AttachmentRow
              pathString={pathString}
              description={description}
              filename={filename}
              disabled={disabled}
              id={`${fieldId}[${valueIndex}]`}
              key={pathString}
              removeAttachment={() => {
                this.onRemoveAttachment(valueIndex, arrayHelpers, pathString, filename);
              }}
              addDescriptionToAttachment={async _description => {
                await this.addDescriptionToAttachment(
                  valueIndex,
                  _description,
                  arrayHelpers,
                  pathString,
                );
              }}
            />
          ))}
        </StyledAttachmentsTable>
        {!disabled && (
          <FileUploader
            id={fieldId}
            onUpload={signedUrl => this.onUploadSuccess(signedUrl, arrayHelpers)}
          />
        )}
      </FilesFieldContainer>
    );
  }

  render() {
    const {
      field: { name },
      resourceName,
      id,
    } = this.props;
    const inputId = id || `${resourceName}-${name}`;
    return (
      <FieldWrapper {...fieldWrapperProps({ inputId, ...this.props })}>
        <FieldArray
          name={name}
          render={arrayHelpers => this.renderAttachments(arrayHelpers, inputId)}
        />
      </FieldWrapper>
    );
  }
}
