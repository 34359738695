import React from 'react';
import { FastField } from 'formik';
import { DefaultAttributesGroup } from '../../../components/form/DefaultAttributesGroup';
import { defaultShouldUpdate, sortFilesZA } from '../../../services/fieldUtils';
import { FormSection } from '../../../components/form/FormSection';
import { TextField } from '../../../components/fields/TextField';
import { API } from '../../../services/api';
import { htmlOptionRenderer } from '../../../components/fields/selectorOptionRenderers/htmlOptionRenderer';
import { defaultOptionRenderer } from '../../../components/fields/selectorOptionRenderers/defaultOptionRenderer';
import { AsyncSelectorField } from '../../../components/fields/AsyncSelectorField';
import { DateTimeField } from '../../../components/fields/DateTimeField';
import { FilesField } from '../../../components/fields/FilesField';
import { DateField } from '../../../components/fields/DateField';

const t = key => I18n.t(`radiation_safety.rad_permits.new_edit.${key}`);

export const Overview = props => {
  const { id } = props;

  return (
    <DefaultAttributesGroup
      defaultChildrenAttributes={{
        labelI18nKeyPrefix: 'activerecord.attributes.rad_permit',
        shouldUpdate: defaultShouldUpdate,
        resourceName: I18n.t('resource_names.rad_permit'),
      }}
    >
      <FormSection title={t('sections.overview')}>
        <FastField name="identifier" disabled component={TextField} />
        <FastField name="rad_permit_name" required component={TextField} />
        <FastField
          name="rad_permit_type_id"
          api={API.selectors.radPermitTypes.index}
          selectionApi={API.selectors.radPermitTypes.show}
          optionRenderer={htmlOptionRenderer('text')}
          headerRenderer={defaultOptionRenderer('text')}
          component={AsyncSelectorField}
        />
        <FastField
          name="holder_id"
          required
          api={API.selectors.people.index}
          selectionApi={API.selectors.people.show}
          optionRenderer={htmlOptionRenderer('text')}
          headerRenderer={defaultOptionRenderer('full_name')}
          component={AsyncSelectorField}
        />
        <FastField
          name="rad_license_id"
          required
          disabled={id}
          api={API.selectors.radLicenses.index}
          selectionApi={API.selectors.radLicenses.show}
          optionRenderer={htmlOptionRenderer('text')}
          headerRenderer={htmlOptionRenderer('text')}
          component={AsyncSelectorField}
        />
        <FastField
          name="rad_permit_status_id"
          api={API.selectors.radPermitStatuses.index}
          selectionApi={API.selectors.radPermitStatuses.show}
          optionRenderer={htmlOptionRenderer('text')}
          headerRenderer={defaultOptionRenderer('text')}
          component={AsyncSelectorField}
        />
        <FastField disabled name="created_at" component={DateTimeField} />
        <FastField name="expiration_date" component={DateField} />
        <FastField
          name="attachments"
          disabled={false}
          component={FilesField}
          sortPredicate={sortFilesZA}
        />
      </FormSection>
    </DefaultAttributesGroup>
  );
};
