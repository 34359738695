import React from 'react';

export const DeleteButton = ({ children, disabled, additionalClasses = '', ...props }) => (
  <button
    type="button"
    className={`btn btn-link text-danger ${disabled ? 'disabled' : ''} ${additionalClasses}`}
    disabled={disabled}
    {...props}
  >
    <i className="fas fa-trash" />
    <span> </span>
    {children}
  </button>
);
