import React from 'react';
import { SmallLightButton } from '../../../components/buttons/SmallLightButton';
import { API } from '../../../services/api';
import { showBackendErrorMessage } from '../../../services/utils';

const t = key => I18n.t(`inventory.equipment.equipment.new_edit.${key}`);

export const PrintLabelButton = ({ equipmentId }) => (
  <SmallLightButton
    onClick={async () => {
      const { ok } = await API.equipment.equipment.printLabel({ ids: equipmentId });
      if (ok) {
        window.open(`/inventory/equipment/equipment/print_labels_pdf?ids=${equipmentId}`);
      } else {
        showBackendErrorMessage(t('flash.error.unexpected_error'));
      }
    }}
  >
    {t('header.print_label_button')}
  </SmallLightButton>
);
