import React, { useRef, useState } from 'react';
import { API } from '../../../../services/api';
import { isDefined } from '../../../../services/utils';
import { SmallLightButton } from '../../../../components/buttons/SmallLightButton';
import { Modal } from '../../../../components/modals/Modal';
import { LogoProfileModalContent } from './index';

const t = key => I18n.t(`hsds.logo_profiles.${key}`);

export const LogoProfileButtons = props => {
  const {
    name,
    currentUser,
    values: { logo_profile_id },
  } = props;

  const [logoProfile, setLogoProfile] = useState({});
  const [modalTitle, setModalTitle] = useState({});
  const logoProfileModalRef = useRef(null);
  const handleAddNewProfileButtonClick = async () => {
    const { ok, data } = await API.spaceRiskAssessments.logoProfiles.new();
    if (ok) {
      await setLogoProfile(data.data.attributes);
      await setModalTitle(t('title.new'));
      logoProfileModalRef.current.open();
    }
  };
  const handleEditProfileButtonClick = async () => {
    const { ok, data } = await API.spaceRiskAssessments.logoProfiles.show(logo_profile_id);
    if (ok) {
      await setLogoProfile(data.data.attributes);
      await setModalTitle(t('title.edit'));
      logoProfileModalRef.current.open();
    }
  };
  return (
    <>
      <div className="d-flex flex-row">
        {isDefined(logo_profile_id) && (
          <SmallLightButton
            additionalClasses={`mr-3 ${name}_edit_profile_button`}
            onClick={handleEditProfileButtonClick}
          >
            {t('edit_profile_button')}
          </SmallLightButton>
        )}
        <SmallLightButton
          additionalClasses={`${name}_new_profile_button`}
          onClick={handleAddNewProfileButtonClick}
        >
          {t('new_profile_button')}
        </SmallLightButton>
      </div>
      <Modal title={modalTitle} ref={logoProfileModalRef}>
        {modalProps => (
          <LogoProfileModalContent
            currentUser={currentUser}
            logoProfile={logoProfile}
            {...modalProps}
          />
        )}
      </Modal>
    </>
  );
};
