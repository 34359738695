import React, { useCallback } from 'react';
import { API } from '../../../../services/api';
import { showBackendErrorMessage } from '../../../../services/utils';
import { SmallLightDropdown } from '../../../buttons/SmallLightDropdown';

const t = key => I18n.t(`chemical_safety.chemical_inventories.new_edit.tabs.chemicals.${key}`);

const disabledLinkStyle = {
  pointerEvents: 'none',
  cursor: 'default',
  opacity: '0.65',
  border: 'none',
};

const Link = ({ onClick, key, children }) => (
  <button
    type="button"
    className="dropdown-item d-flex justify-content-between align-items-center"
    onClick={async () => {
      await onClick();
    }}
    key={key}
  >
    {children}
  </button>
);

export const PrintLabelsButton = ({ ids, disabled }) => {
  const printLabels = useCallback(
    async size => {
      const { ok } = await API.chemicalSafety.chemicals().printLabelsPdf({ ids, size });
      if (ok) {
        window.open(`/chemical_safety/chemicals/print_labels_pdf?ids=${ids}&size=${size}`);
      } else {
        showBackendErrorMessage(t('flash.error.unexpected_error'));
      }
    },
    [ids],
  );

  const renderItems = useCallback(
    () =>
      ['small', 'large'].map(size => (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <Link
          key={size}
          onClick={async () => {
            await printLabels(size);
          }}
        >
          {t(`buttons.size.${size}`)}
        </Link>
      )),
    [printLabels],
  );

  const linkStyle = disabled ? { ...disabledLinkStyle } : {};

  return (
    <SmallLightDropdown renderItems={renderItems} style={linkStyle}>
      {t('buttons.print_labels')}
    </SmallLightDropdown>
  );
};
