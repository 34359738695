import React from 'react';

const produceOnClick = onClick => e => {
  if (!onClick) return;

  e.preventDefault();
  onClick(e);
};

export const SecondaryButton = ({
  children,
  onClick,
  disabled,
  additionalClasses = '',
  ...props
}) => (
  <button
    type="button"
    className={`btn btn-secondary ${disabled ? 'disabled' : ''} ${additionalClasses}`}
    onClick={produceOnClick(onClick)}
    disabled={disabled}
    {...props}
  >
    {children}
  </button>
);
